.header {
  background-color: rgb(38, 38, 38);
  overflow: hidden;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 44px;
}

:global(.dark) .header {
  background-color: rgb(0, 0, 0);
}

.title_text {
  color: #cecdcd;
  font-size: medium;
  padding-left: 5px;
}

:global(.dark) button {
  background: #393939;
  color: rgb(228, 228, 228);
  border-color: #7f7f7f;
}

.checkbox {
  -ms-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
  margin-top: 10px;
  padding: 4px 12px;
  border-radius: 5px;
}

button {
  margin: 2px;
  padding: 4px 12px;
  border-radius: 5px;
}


.label {
  color: #cecdcd;
  margin: 3px;
}

#theme_button {
  width: 120px;
}