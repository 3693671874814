.tab_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.tab_button {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom-width: 0px;
  border-bottom-style: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#mode_button {
  width: 110px;
}

.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}

.diffmode_guide {
  font-family: sans-serif;
  font-size: 17px;
  padding: 15px;
  margin: 10px;
  overflow: hidden;
}

.diffmode_guide {
  font-family: sans-serif;
  font-size: 17px;
  padding: 15px;
  margin: 10px;
  overflow: hidden;
}

:global(.dark) .diffmode_guide {
  color: #cfcec4;
}
