.option {
  margin: 5px;
  padding: 3px 0px;
  display: flex;
  justify-content: space-between;
}

.title {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 17px;
  overflow: hidden;
}

.title_deprecated {
  font-family: sans-serif;
  font-size: 17px;
  overflow: hidden;
  text-decoration: line-through
}


:global(.dark) .title {
  color: #cfcec4;
}

:global(.dark) .title_deprecated {
  color: #cfcec4;
}

.title_container {
  max-width: calc(100% - 235px);
  padding: 3px;
}

.overridden_waring {
  font-family: sans-serif;
  font-size: 10px;
  overflow: hidden;
  color: red;
}

:global(.dark) .overridden_waring {
  color: #c98787;
}



.docstring {
  min-height: 20px;
  padding: 15px;
  margin: 10px;
  padding-top: 5px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
}

:global(.dark) .docstring {
  background-color: #2b2e32;
  border-color: #141414;
  color: #d0cec5;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

:global(.dark) code {
  background-color: rgb(79, 44, 53);
  color: rgb(245, 95, 133);
}

pre {
  overflow-x: scroll;
  max-width: 100%;
  padding-bottom: 8px;
  font-size: inherit;
  color: inherit;
  border-style: solid;
  border: 1px solid #e3e3e3;
  background-color: #f8f8f8;
  border-radius: 4px;
  white-space: pre;
  padding-top: 10px;
  font-weight: 200;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

:global(.dark) pre {
  background-color: #434343;
  scrollbar-color: dark;
  border-color: #1a1a1a;
}

.info_button {
  display: inline-block;
  width: 13px;
  height: 13px;
  padding: 0;
  border: none;
  background: none;
  transition-duration: 100ms;
  opacity: 0.9;
}

.info_button:hover {
  opacity: 0.8;
  transition-duration: 100ms;
}

.info_button:active {
  opacity: 1;
  transition-duration: 20ms;
}
