.selector {
  width: 200px;
  margin: 2px;
  padding: 4px 12px;
  border-radius: 5px;
  border-width: 1px;
  background-color: rgb(233, 233, 233);
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -moz-appearance: none !important;
  appearance: none;
}

:global(.dark) .selector {
  background-color: rgb(52, 52, 52);
  color: rgb(211, 211, 211);
}

.selector:disabled {
  background-color: rgb(189, 189, 189);
}

:global(.dark) .selector:disabled {
  background-color: rgb(33, 33, 33);
}

.selector_disableable {
  width: 183px;
  float: left;
}


.dropdown_option_default {
  -moz-appearance: none;
  appearance: none;
  font-style: italic;
  background-color: rgb(184, 184, 184);
}

:global(.dark) .dropdown_option_default {
  background-color: rgb(203, 203, 203);
  color: #343434;
}


.nested_container {
  padding-left: 0px;
  width: 230px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #c0c0c0;
}

:global(.dark) .nested_container {
  border-color: #161616;
}

.title_nested {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 17px;
  display: inline-block;
  margin: 5px 0px;
}

:global(.dark) .title_nested {
  color: #cfcdc3;
}

.button_array {
  width: 225px;
  height: 27px;
  margin: auto;
  display: block;
  padding: 0px;
  border: none;
  background: none;
  opacity: 0.8;
  transition-duration: 100ms;
}

.button_array:hover {
  opacity: 0.7;
  transition-duration: 100ms;
}

.button_array:active {
  opacity: 1;
  transition-duration: 50ms;
}


.image_button_array {
  max-width: 100%;
  max-height: 100%;
}


.button_activeness {
  margin: 2px 0px;
  border: solid;
  border-width: 1px;
  padding: 0px;
  border: none;
  background: none;
  height: 25px;
  width: 15px;
  opacity: 0.9;
}

:global(.dark) .button_activeness {
  filter: brightness(50%);
}

.button_activeness:hover {
  opacity: 0.7;
  transition-duration: 100ms;
}

.button_activeness:active {
  opacity: 1;
  transition-duration: 100ms;
}


input[type=number] {
  -moz-appearance: textfield;
}

:global(.dark) .popup_hint {
  color: #e2e1d8;
  border-color: #202020;
}
