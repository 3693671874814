.error{
    padding: 0px;
    margin: 0px;
}

:global(.dark) .error{
    background-color: #313131;
    color: rgb(226, 226, 226);
}

.error_header{
    text-align: center;
    font-weight: 400;
    margin: 5px;
}

.error_message{
    white-space: pre-wrap;
    text-align: center;
    font-weight: 400;
    font-family: monospace;
    margin: 5px;
}