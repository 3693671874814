body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
}

body.dark {
  background-color: rgb(34, 34, 38)
}

.pane_container {
  display: flex;
}


.left_side {
  width: 60vw;
  border-right-style: solid;
}

.dark .left_side {
  border-right-color: #5b5b5b;
}

.optionList_container {
  max-height: calc(100vh - 44px);
  overflow: scroll;
}

.right_side {
  flex-grow: 1;
  overflow: hidden;
}

.config_page_popup-content {
  height: 70vh;
  width: 70vw;
  min-width: 900px;
  border-radius: 5px;
}

.dark .config_page_popup-content {
  background-color: #313131;
  border-color: #000000;
  border-width: 2px;
}


.error_popup-content {
  position: absolute;
  top: -35%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 55%;
  padding: 0px;
  margin: 0px;
}

.dark .error_popup-content {
  background-color: #313131;
  border-color: #000000;
  border-width: 2px;
}


.error_popup-overlay {
  background: rgba(0, 0, 0, 0.13);
}

.dark ::-webkit-scrollbar {
  height: 12px;
  width: 9px;
  background: rgb(59, 59, 59);
}

.dark ::-webkit-scrollbar-thumb {
  background: #262626;
  border-color: black;
}
